<template>
  <div
    class="row justify-center bg-grey-3"
    style="min-height:calc(100vh - 83px)"
  >
    <q-card flat bordered style="width:300px;">
      <q-tabs
        v-model="tab"
        no-caps
        class="bg-primary text-white"
        @input="getRiwayat"
      >
        <q-tab name="tahfidz" label="Tahfidz" />
        <q-tab name="mentoring" label="Mentoring" />
      </q-tabs>
      <q-card-section class="q-gutter-md">
        <q-select
          outlined
          v-can="['KEPALA', 'WAKA']"
          v-model="selMusyrif"
          label="Musyrif"
          :options="musyrif"
          @input="getRiwayat"
        />
        <q-input outlined label="Tanggal" v-model="date" readonly>
          <template v-slot:prepend>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy transition-show="scale" transition-hide="scale">
                <q-date
                  :options="dateOptionFn"
                  v-model="date"
                  mask="YYYY/MM/DD"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-select
          outlined
          stack-label
          label="Status"
          :options="opt"
          v-model="status"
        ></q-select>
        <q-input
          outlined
          bottom-slots
          v-model="keterangan"
          label="keterangan"
          maxlength="250"
        >
          <template v-if="keterangan != ''" v-slot:hint
            >{{ keterangan.length }} / 250</template
          >
        </q-input>
      </q-card-section>
      <q-card-actions class="q-pa-none" align="stretch">
        <q-btn
          unelevated
          :disable="status == null"
          color="negative"
          class="full-width"
          label="Simpan"
          v-can="['TAHFIDZ', 'MENTOR']"
          @click="simpan"
        ></q-btn>
      </q-card-actions>
      <q-separator></q-separator>
      <q-markup-table
        flat
        dense
        wrap-cells
        class="stickyTable bg-grey-4"
        separator="horizontal"
        style="height:calc(100vh - 420px)"
      >
        <thead class="bg-indigo-5">
          <th>tanggal</th>
          <th>status</th>
          <th>keterangan</th>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in riwayat" :key="i">
            <td>{{ val.tanggal | moment("DD MMM YYYY") }}</td>
            <td>{{ val.status }}</td>
            <td>{{ val.keterangan }}</td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: "tahfidz",
      date: moment().format("YYYY/MM/DD"),
      opt: ["HADIR", "SAKIT", "IZIN"],
      status: null,
      keterangan: "",

      user: {},
      musyrif: [],
      selMusyrif: {
        value: null,
      },

      riwayat: [],
    };
  },
  async mounted() {
    await this.getUser();
    if (
      this.user.is_super_user == "1" ||
      this.user.is_kepala_pengasuhan == "1" ||
      this.user.is_waka == "1"
    ) {
      await this.getMusyrif();
    } else {
      this.selMusyrif.value = this.user.id;
    }
    await this.getRiwayat();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(`/penilaian/absensi/getmusyrif`);
      this.musyrif = resp.data;
      if (resp.data.length > 0) {
        this.selMusyrif = resp.data[0];
      }
      this.$q.loading.hide();
    },
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async getRiwayat() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengaturan/absensi_musyrif/getriwayat/${this.selMusyrif.value}/${
          this.tab
        }/${localStorage.getItem("id_tahun_ajaran")}`
      );
      for (let item of resp.data) {
        if (item.is_hadir == "1") {
          item.status = "HADIR";
        } else if (item.is_sakit == "1") {
          item.status = "SAKIT";
        } else if (item.is_izin == "1") {
          item.status = "IZIN";
        }
      }
      this.riwayat = resp.data;
      this.$q.loading.hide();
    },
    dateOptionFn(date) {
      return date <= moment().format("YYYY/MM/DD");
    },
    async simpan() {
      let data = {
        id_musyrif: this.selMusyrif.value,
        id_tahun_ajaran: localStorage.getItem("id_tahun_ajaran"),
        tipe: this.tab,
        tanggal: this.date,
        keterangan: this.keterangan,
        is_hadir: 0,
        is_izin: 0,
        is_sakit: 0,
      };
      if (this.status == "HADIR") {
        data.is_hadir = 1;
      } else if (this.status == "SAKIT") {
        data.is_sakit = 1;
      } else if (this.status == "IZIN") {
        data.is_izin = 1;
      }
      await this.$http.post(`/pengaturan/absensi_musyrif/add`, data);
      this.$q.notify({
        message: "Absensi Berhasil Disimpan",
        color: "positive",
      });
      await this.getRiwayat();
    },
  },
};
</script>
